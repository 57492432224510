<template>
  <view class="">
    <div id="home" class="home">
      <a-row>
        <a-col :span="24">
          <div style="height: 680px;"><img src="@/assets/img/head.png" style="width: 100%;height: 100%;">
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="main">
        <h3 class="title">低空管制 无人机避碰方案</h3>
        <div class="title-des">
          无人机避碰模块: 2024年1月1日起施行的《无人驾驶航空器飞行管理暂行条例》中明确规定，操控无人驾驶航空器实施飞行活动，应当掌握飞行空域内其他航空器的飞行动态，采取避免相撞的措施。我司的飞机外挂碰壁模块，采用MavLink协议兼容Pixhawk4.0飞控平台，即插即用，帮助无人机实现自主避碰功能。无人机远程识别模块:2024年6月1日起实施的《民用无人驾驶航空器系统安全要求强制性国家标准中，规定了轻型和小型无人驾驶航空器实施飞行活动，应通过网络主动向综合监管服务平台报送识别信息，应通过无线局域网 (Wi-Fi) 或蓝牙自动广播识别信息。我司的无人机远程识别装置可使无人机出厂或后装的情况下，适应该国标关于无人机远程识别综合监管的要求。
        </div>
        <h4 class="title">远程识别 智能避碰</h4>
        <div class="method">
            <view class="item" @mouseover="showDes=true" @mouseleave="showDes=false">
              <view> <img src="@/assets/img/item/left.png" alt="" @click="toProductDetail(4)" srcset=""></view>
              <view class="item_title" @click="toProductDetail(4)">
                无人机远程识别模块（嵌入式）
              </view>
              <view class="des" v-show="showDes===true" @click="toProductDetail(4)">
                本模组可用于无人机远程识别信号发射，模组支持 2.4GWiFi， BT4，BT5 等频段同时广
                播信号，用户可将本模组焊接在无人机主板上，使用简单的外围电路即可让无人机具有实时飞行信息广播的功能。
              </view>
            </view>
            <view class="content"><img src="@/assets/img/item/method_content.png" alt="" srcset=""></view>
            <view class="item" @mouseover="showDes2=true" @mouseleave="showDes2=false">
              <img class="right_200" src="@/assets/img/item/right.png"  @click="toProductDetail(1)" alt="" srcset="">
              <view class="item_title right" @click="toProductDetail(1)">
                无人机避碰模块
              </view>
              <view class="des right" v-show="showDes2===true" @click="toProductDetail(1)">
                本模组可用于无人机远程识别信号发射，模组支持 2.4GWiFi， BT4，BT5 等频段同时广
                播信号，用户可将本模组焊接在无人机主板上，使用简单的外围电路即可让无人机具有实时飞行信息广播的功能。
              </view>
            </view>
        </div>
        <h4 class="title">方案示意</h4>
        <div class="shiYi"><img src="@/assets/img/item/sy.png" alt="" srcset=""></div>
        <div class="plan">
          <p>1.当装配无人机避碰模块的无人机与其他飞行器靠近时，模块能够通过MAVLink通讯协议，帮助无人机实现自主避碰的功能。</p>
          2.远程识别模块 RR-Pioneer可以通过无线局域网 (Wi-Fi) 或蓝牙自动广播识别信息，并获取其飞行参数、位置（纬度和经度）、航速、序列号，甚至包括操控人员等身份信息。一旦地面人员或监管系统发现无人机在附近飞行或进入了禁飞区域，可以通过信号接收到对应的无人机信息并采取相应措施。
        </div>
<!--        <h4 class="title">通用航空系列</h4>-->
<!--        <div class="foot">-->
<!--          <view class="info">-->
<!--            <view class="img"> <img src="@/assets/img/item/l.jpg" alt="" srcset=""></view>-->
<!--            <view class="small">无人机远程识别模块</view>-->
<!--            <el-divider content-position="right" class="active"></el-divider>-->
<!--            <view class="nav" @click="toProductDetail(4)"><span>查看详情</span>    <arrow-right-outlined :style="{ fontSize: '28px' }"/></view>-->
<!--          </view>-->
<!--          <view class="info">-->
<!--            <view class="img"> <img src="@/assets/img/item/r.jpg" alt="" srcset=""></view>-->
<!--            <view class="small"> 无人机避碰模块 </view>-->
<!--            <el-divider content-position="right" class="active"></el-divider>-->
<!--            <view class="nav" @click="toProductDetail(1)"><span>查看详情</span>    <arrow-right-outlined :style="{ fontSize: '28px' }"/></view>-->
<!--          </view>-->
<!--        </div>-->
    </div>
  </view>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';

import {ArrowRightOutlined} from "@ant-design/icons-vue";

export default {
  name: "ProductItem",
  data() {
    // 这里存放数据
    return {
      showDes:false,
      showDes2:false,
    }
  },
  // import 引入的组件需要注入到对象中才能使用
  components: {ArrowRightOutlined},
  props: {},
  // 方法集合
  methods: {
    toProductDetail(id) {
      this.$router.push({
        path: "/productDetail",
        query: { //query是个配置项
          tab: id
        }
      })
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  //过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate() {
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created() {
  },
  // 生命周期 - 挂载之前
  beforeMount() {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
  },
  // 生命周期 - 更新之前
  beforeUpdate() {
  },
  // 生命周期 - 更新之后
  updated() {
  },
  // 生命周期 - 销毁之前
  beforeDestroy() {
  },
  // 生命周期 - 销毁完成
  destroyed() {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  //进入的时候触发
  activated() {
  },
  //离开的时候触发
  deactivated() {
  },
}
</script>

<style  scoped >
.main  .title{
  padding-top: 100px;
  color: #fff;
  display: flex;
  justify-content: center;

  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
}
.main .title-des{
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(255, 255, 255, 1);

}
.main .method{
  display: flex;
  margin-top: 120px;
  justify-content: space-between;



}
.main .method  .item{
  display: flex;
  align-items: center;
  position:relative;
}
.main .method  .item  img{
  z-index: 1;
  height: 153px;
  position: absolute;
  left: 20px;
  bottom:150px;
  cursor: pointer;
}
.main .method  .item .right_200{
  left: -245px;
  bottom: 135px;
}
.main .method  .item .des{
  padding-top: 40px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20.27px;
  color: rgba(255, 255, 255, 1);
  width: 270px;
  z-index: 1;
  position: absolute;
  margin-top: 150px;
}
.main .method  .item .right{
  left: -245px;
}
.main .method  .item  .item_title{
  display: flex;
  justify-content: center;
  padding-top: 40px;
  width: 270px;
  z-index: 1;
  position: absolute;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 43.44px;
  color: rgba(255, 255, 255, 1);
}
.main .method .content{

}
.main .method .content img{
  height: 280px;
}
.main .shiYi  img{
  height: 440px;
}
.main  .plan{
  color: #fff;
  margin-top: 80px;
  padding-bottom: 415px;
}
.main{
  background: #1C1F2B;
  padding: 0 300px;
  min-width: 1280px;




}
.main .foot{
  display: flex;
  justify-content: space-between;
  margin-top: 90px;

}
.main .foot  .info{
  display: flex;
  flex-direction: column;

}
.main .foot  .info .img img{
  height: 360px;
  width: 360px;
}
.main .foot  .info .small{
  margin-top: 30px;
  font-size: 30px;
  font-weight: 700;
  line-height: 44px;
  color: rgba(255, 255, 255, 1);
}
.main .foot  .info .active{
  height: 4px;
  width: 100px;
  background-color: rgba(53, 148, 255, 1);
}
.main .foot  .info .nav{
  cursor: pointer;
  margin-top: 30px;
  margin-bottom: 290px;
  font-size: 20px;
  font-weight: 500;
  line-height: 50px;
  color: rgba(255, 255, 255, 1);
  width: 170px;
  height: 50px;
  border: 1px solid #fff;
  border-radius: 15px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;

}
</style>
