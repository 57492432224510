<template>
    <div id="footer">
        <footer>
            <a-row class="desc">
                <a-col :span="3">
                    <p class="h3"><router-link to="/about?tab=1">关于我们</router-link></p>
                    <p><router-link to="/about?tab=1">公司简介</router-link></p>
                    <p><router-link to="/about?tab=2">公司历史</router-link></p>
                    <p><router-link to="/about?tab=3">公司荣誉</router-link></p>
                </a-col>
                <a-col :span="3">
                    <p class="h3"><router-link to="/product">产品中心</router-link></p>
                    <p><router-link to="/productDetail?tab=1">远程识别系列</router-link></p>
                    <p><router-link to="/productDetail?tab=4">通用航空系列</router-link></p>
                    <p><router-link to="/productDetail?tab=7">船舶管控系列</router-link></p>
                    <p><router-link to="/productDetail?tab=10">智慧城市</router-link></p>
                </a-col>
                <a-col :span="3">
                    <p class="h3"><router-link to="/news">新闻动态</router-link></p>
                </a-col>
                <a-col :span="3">
                    <p class="h3"><router-link to="/contact?tab=1">联系我们</router-link></p>
                    <!-- <p><router-link to="/contact?tab=1">加入我们</router-link></p>
                    <p><router-link to="/contact?tab=2">客服热线</router-link></p> -->

                </a-col>
                <a-col :span="4" class="qr">
                    <div class="wechat white">
                        <img src="@/assets/img/tb.png" alt="">
                        <p>官方淘宝店铺</p>
                    </div>
                    <div class="wechat white">
                        <img src="@/assets/img/qr.jpg" alt="">
                        <p>官方微信</p>
                    </div>
                    <div class="wechat white">
                      <img src="@/assets/img/swwx.png" alt="">
                      <p>商务微信</p>
                    </div>
                </a-col>

                <a-col :span="8" class="white right-foot">

                    <p style="font-size: 12px;position: absolute;bottom: 0">地址：上海市宝山区克山路8号吴淞生态产业园9号楼B区4楼</p>
                </a-col>
            </a-row>

            <a-divider class="divider" />
            <a-row>
                <a-col :span="24">
                    <div class="record">
                        <div class="left"> ©{{ `${year} ` }} 上海忆伯科技有限公司 &nbsp;&nbsp;备案号：<a style="cursor: pointer;"
                                href="https://beian.miit.gov.cn" target="_blank">沪ICP备2023012862号-1</a>
                        </div>
                        <div class="right">
                            <p>法律声明</p>
                            <p>隐私政策</p>
                            <p>联络我们</p>
                        </div>
                    </div>

                </a-col>
            </a-row>
        </footer>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            year: new Date().getFullYear(),
        }
    },

}
</script>

<style scoped>
a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #fff;
    /* 设置使所有a标签的四种状态都和本身颜色保持一致，样式代码自己写 */
}

.router-link-active {
    border-bottom: none;
}

#footer {
    width: 100%;
    min-width: 1280px;
    color: rgba(214, 214, 214, 1);
    background-color: #1F1F21;

    /* padding-bottom: .5rem; */
    box-shadow: 0 1px 10px rgba(130, 130, 134, 0.1);
    padding-left: 300px;
    padding-right: 300px;
}

.desc {
    padding-top: 100px;
    /* height: 300px; */
}

.desc .h3 {
    font-size: 16px;
    color: #fff;
    margin-bottom: 30px;
}

.desc p {
    margin-bottom: 20px;
}

.record {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.record .right {
    display: flex;
    width: 288px;
    justify-content: space-between;
}

.white {
    color: #fff;
}

.divider {
    margin: 0;
    background: rgba(170, 170, 170, 1);
}

.wechat {
    width: 84px;
    height: 78px;
    text-align: center;
    margin-left: 40px;
}

.wechat img {
    width: 78px;
    height: 78px;
}

.right-foot {
    padding-left: 105px;
}

.qr {
    display: flex;
}

.qr .wechat p {
    display: inline-block;
    width: 84px !important;
    margin-top: 20px;
}
</style>

