export default [
  {
    id: 1,
    sort: 1,
    group: "#远程识别系列#",
    title: "无人机远程识别模块（嵌入式）",
    content:
      "2024年6月1日起正式实施《民用无人驾驶航空器系统安全要求》强制性国家标准（GB 42590-2023），该标准是《无人驾驶航空器飞行管理暂行条例》的配套支撑标准，适用于除航模之外的微型、轻型、小型民用无人驾驶航空器（最大起飞重量25KG以下）。我司的无人机远程识别装置可使无人机出厂或后装的情况下，适应该国标关于无人机远程识别综合监管的要求。",
    date: "2023.11.13",
    des: "",
    cover: "/product/img_RR-pioneer.png",
    imgs: [
      {
        src: "/product/yckz/find.png",
        des: "发现无人机",
      },
      {
        src: "/product/yckz/look.png",
        des: "通过软件查看",
      },
      {
        src: "/product/yckz/identify.png",
        des: "无人机识别",
      },
      {
        src: "/product/yckz/law.png",
        des: "相关法律法规",
      },
    ],
    params: "/params/img_RR-pioneer.png",
    tab1: "功能介绍",
    tab4: "前往购买",
    tab3: "相关参数",
    height: ["简单易用", "多种解决方案", "轻松配置应答器"],
    // pdf: "/down/RR-Pioneer.pdf",
  },
  {
    id: 2,
    sort: 2,
    group: "#远程识别系列#",
    title: "无人机远程识别外挂模块",
    content:
      "<p>新规强制规定我国的专业飞手应当将他们的无人机信息数字化，我司因而研发了远程识别“飞手小方”，并已获得FAA官方认证。该外挂式独立模块可使无人机完全符合无人机飞行要求标准，无需购买新的无人机。</p>",
    cover: "product/img_fdxs.png",
    imgs: [
      {
        src: "/product/RRpioneer/img_range.png",
        des: "内置2.4G&GNSS天线",
      }, {
        src: "/product/RRpioneer/img_endurance.png",
        des: "超长电池续航",
      }, {
        src: "/product/RRpioneer/img_position.png",
        des: "多地理定位标准",
      }, {
        src: "/product/RRpioneer/img_flight.png",
        des: "从起飞到降落完咸无忧飞行",
      }
    ],
    params: "/params/img_fdxs.png",
    tab1: "产品特点",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [

    ],
  }, {
    id: 3,
    sort: 3,
    group: "#远程识别系列#",
    title: "无人机远程识别地面站",
    content:
      "<p>RR-vision远程识别地面站由多个无线电接口组成，用于收集远程识别信号，10/ 100M 以太网接口符合国际标准\n" +
      "国标：GB 42590-2023 \n" +
      "欧盟：ASD-STAN DIN EN 4709-002 \n" +
      "美国：ASTM Remote ID Standard ASTM F3411-22a-RID-B/ F3586-22</p>",
    cover: "/product/wrdm.png",
    imgs: [
      {
        src: "/product/remote/bdz.png",
        des: "变电站",
      },
      {
        src: "/product/remote/jc.png",
        des: "机场",
      },
      {
        src: "/product/remote/tycg.png",
        des: "体育场馆",
      },
      {
        src: "/product/remote/xx.png",
        des: "学校",
      },
    ],
    params: "/params/dmz.png",
    tab1: "产品特点",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [

    ],
  },
  {
    id: 4,
    sort: 4,
    group: "#通用航空系列#",
    title: "ADSR800",
    content:
      "采用直放式无线电接收技术，用于解调 ADS-B Mode S 信号的无线接收器接收器的信号输入端使用多个窄带滤波器，滤除空间中大部分带外噪声接收器的 PCB 经过精心设计，具有很好的本底噪声接收器内部的处理器可以捕获 uV 级的射频信号，捕获速度可达 nS 级，无论距离远近的目标都能良好接收信号接收器工作频率为 1090MHz，当接收器接收到 ADS-B 射频信号以后将自动解调信号，接收器的 TTL 接口将同步输出解调成功的 MavLink V2.0 格式电文",

    cover: "/product/img_ADSR800.png",
    imgs: [
      {
        src: "/product/ADSR800/fxmnq.jpg",
        des: "飞行模拟器",
      },
      {
        src: "/product/ADSR800/kyjk.jpg",
        des: "空域监控",
      },
      {
        src: "/product/ADSR800/kzbz.jpg",
        des: "空中避碰",
      },
      {
        src: "/product/ADSR800/pw.jpg",
        des: "皮卫星载荷",
      },
    ],
    params: "/params/ADSR800.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [
      "极高灵敏度",
      "出色的带外抑制",
      "nS 级的捕获速度",
      "高可靠性",
      "体积小巧",
    ],
    pdf: "/down/ADSR800.pdf",
  },
  {
    id: 5,
    sort: 5,
    group: "#通用航空系列#",
    title: "AIS112",
    content:
      "应用于微纳卫星设计的低成本星基 AIS 接收载荷，适用于 600km 以下轨道的卫星使用接收器拥有 2 路独立解调硬件的 AIS 解调通道，可通过组合叠加的方式实现 4 通道本接收器采用 2 次变频技术，具有极高的接收灵敏度接收器的信号调节通道上采用多个窄带滤波器，可滤除空间中大部分的带外噪声，以获得更好信噪比该接收器可以捕获极微小的 AIS 信号，获得良好的在轨接收效果",
    date: "2023.04.03",
    des: "忆伯科技荣获上海市专精特新中小型企业资质。",
    cover: "/product/img_AIS112.png",
    imgs: [
      {
        src: "/product/AIS112/hssj.jpg",
        des: "海上搜救",
      },
      {
        src: "/product/AIS112/wrj.jpg",
        des: "无人机",
      },
      {
        src: "/product/AIS112/wnwx.jpg",
        des: "微纳卫星",
      },
      {
        src: "/product/AIS112/tkqq.jpg",
        des: "探空气球",
      },
    ],
    params: "/params/AIS112.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [
      "极高灵敏度",
      "高可靠性",
      "出色的带外抑制",
      "uS 级的捕获速度",
      "体积小巧",
    ],
    pdf: "/down/AIS112.pdf",
  },
  {
    id: 6,
    sort: 6,
    group: "#通用航空系列#",
    title: "ADSR-800",
    content:
      "采用直放式无线电接收技术接收机内部主要由多个窄带滤波器和低噪声放大器组合而成，以提高接受性能接收机检波部分采用高速高动态响应的对数检波器，解码部分使用硬件电路直接解码后端的 DSP 将 BPPM 信号进行 CRC24 校验后，通过串口输出本接收机的响应速度极快解码几乎没有延时，接收机拥有出色的接收灵敏度",

    cover: "/product/img_ADSR-800.png",
    imgs: [
      {
        src: "/product/ADSR-800/dbjsq.jpg",
        des: "单兵接收",
      },
      {
        src: "/product/ADSR800/kzbz.jpg",
        des: "动态监控",
      },
      {
        src: "/product/ADSR-800/wrjbp.jpg",
        des: "无人机避碰",
      },
      {
        src: "/product/ADSR-800/ADS-B.jpg",
        des: "ADS-B信号接收基站",
      },
    ],
    params: "/params/ADSR-800.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [
      "高灵敏度",
      "低延迟",
      "超宽的动态范围",
      "低温飘",
      "出色的可靠性",
      "体积小巧",
    ],
    pdf: "/down/ADSR-800.pdf",
  },
  {
    id: 7,
    sort: 7,
    group: "#船舶管控系列#",
    title: "AIT33",
    content:
      "AIT33是用于AIS射频信号的发射模组，用户通过UART接口向模组写入用户数据，模组收到数据后即可将数据调制成具有帧头、数据、校验字的GMSK信号并通过射频口发射AIS信号。",
    date: "2023.03.31",
    des: "忆伯科技受邀出席大会，一同推动无人机行业安全和高效发展。",
    cover: "/product/img_AIT33.png",
    imgs: [
      {
        src: "/product/AIT33/wrj.jpg",
        des: "无人机",
      },
      {
        src: "/product/AIT33/aissfq.jpg",
        des: "AIS收发器",
      },
      {
        src: "/product/AIT33/wrc.jpg",
        des: "无人船",
      },
      {
        src: "/product/AIT33/AIS-MOB.jpg",
        des: "AIS-MOB",
      },
    ],
    params: "/params/AIT33.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    height: ["高集成度", "低功耗", "出色的可靠性", "体积小巧"],
    pdf: "/down/AIT33.pdf",
  },
  {
    id: 8,
    sort: 8,
    group: "#船舶管控系列#",
    title: "AIS112E",
    content:
      "AIS112E 是拥有 2 路独立解调硬件的 AIS 接收器。该接收器采用 2 次变频技术具有极高的灵敏度，在接收器的信号输入端使用了多个窄带滤波器，可以滤除空间中大部分的带外噪声以获得更好信噪比，接收器的 PCB 板经过精心设计，具有很好的本底噪声。与本公司其他接收器相比，在同等条件下该接收器可以捕获极微小的 AIS 信号，获得更好的接收效果。该接收器可同时工作在 161.975MHz 和 162.025MHz 两个频率. 当该接收器接收到 AIS 射频信号以后将自动将信号解调，接收器的 RS485 和 RJ45 接口将同步输出解调成功的 AIS 电文。",
    date: "2022.06.01",
    des: "忆伯科技与济南有人物联网成功签署战略合作协议，达成战略合作。",
    cover: "/product/img_AIS112E.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    imgs: [
      {
        src: "/product/AIS112E/hb.jpg",
        des: "AIS航标",
      },
      {
        src: "/product/AIS112E/dzht.jpg",
        des: "电子海图",
      },
      {
        src: "/product/AIS112E/hhmnq.jpg",
        des: "航海模拟器",
      },
      {
        src: "/product/AIS112E/tkqq.jpg",
        des: "探空气球",
      },
    ],
    params: "/params/AIS112E.png",
    pdf: "/down/AIS112E.pdf",
  },
  {
    id: 9,
    sort: 9,
    group: "#船舶管控系列#",
    title: "AIS-MOB",
    content:
      "<p>AIS-MOB 是一种可安装于救生衣或随身携带的便携式应急示位报警装备。该设备浸水后便自动开启并触发\n" +
      "警报，自动发射求救信号至附近船舶。可连续工作 48 小时以上，无需定位系统的支持。求救信号被接收后\n" +
      "会在船舶电子海图或者 AIS 设备上发出警报，并显现落水人员的经纬度坐标、速度、航向、方位、行动轨\n" +
      "迹等基本信息，以便救援人员快速找到落水者。</p>",

    cover: "/product/AIS-MOB.png",
    imgs: [
      {
        src: "/product/AIS-MOB/fc.png",
        des: "帆船",
      },
      {
        src: "/product/AIS-MOB/ssyl.png",
        des: "水上娱乐",
      },
      {
        src: "/product/AIS-MOB/ssjy.png",
        des: "水上救援",
      },
      {
        src: "/product/AIS-MOB/hc.png",
        des: "货船",
      },
    ],
    params: "/params/AIS-MOB.png",
    tab1: "应用场景",
    tab4: "前往购买",
    tab3: "相关参数",
    height: [

    ],
  },
  {
    id: 10,
    sort: 10,
    group: "#智慧城市系列#",
    title: "勤务数字化定位系统（移动端）",
    content:
      "<p>勤务数字化定位系统（移动端）采用前后端分离模式，主要有人员管理、设备管理、产品管理、车辆位置可视化展示对上层指挥调度平台的兼容等，为用户提供可视化界面和有效的信息位置，有利于指挥中心更加快捷地做出决策和人力部署。</p>",

    cover: "/product/img_.png",
    imgs: [
      {
        src: "/scene/scene2.jpg",
        des: "消防车调配",
      },
      {
        src: "/scene/scene3.jpg",
        des: "救护车调配",
      },
      {
        src: "/scene/scene4.jpg",
        des: "警车调配",
      },
      {
        src: "/scene/scene5.jpg",
        des: "摩托车调配",
      },
    ],
    params: "/params/params5.png",
    tab1: "应用场景",
    tab4: "",
    tab3: "方案亮点",
    height: [
      "冷启动5秒内运行满足快速响应",
      "5G高速通讯传输装置小巧便捷",
      "可视化界面实时监测操作简单方便上手",
      "接口满足需求系统集中安全",
    ],
  },
  {
    id: 11,
    sort: 11,
    group: "#智慧城市系列#",
    title: "非机动车智能管控",
    content:
      "<p>非机动车智能管控系统 运用了物联网设备的数据采集和处理，主要由移动 2G 通讯技术、GPS 卫星定位技\n" +
      "术、外置高精度温度传感探头技术、继电器技术、单片机定时技术组成，主要实现非机动车智能化管理：\n" +
      "车辆充电防火断电预警和营运电动车改装电池行为监测的电动车充电防火。\n" +
      "产品安装在电池充电口上。在车辆充电口上加装后，无需接线、即插即用。</p>",

    cover: "/product/fjdczn.png",
    imgs: [

    ],
    params: "/params/fjdParam.png",
    tab1: "应用场景",
    tab4: "",
    tab3: "方案亮点",
    height: [

    ],
  },
  {
    id: 12,
    sort: 12,
    group: "#智慧城市系列#",
    title: "工牌记录仪",
    content:
      "<p>工牌记录仪是一款具有音视频记录功能的可佩戴智能电子工牌，设备采用电子墨水屏设计，可灵活配置电子工牌信息。无线系列摄像机（渠道）安装简单、易操作、无需布网线，非常适合中小型应用场景，如家庭/住宅、小型零售店和其他典型的中小企业应用场景等。</p>",

    cover: "/product/gpjly.png",
    imgs: [
      {
        src: "/product/gpjly/jt.png",
        des: "交通",
      },
      {
        src: "/product/gpjly/ga.png",
        des: "公安",
      },
      {
        src: "/product/gpjly/zf.png",
        des: "政府",
      },
      {
        src: "/product/gpjly/jr.png",
        des: "金融",
      },
    ],
    params: "/params/gpjly.png",
    tab1: "应用场景",
    tab4: "",
    tab3: "相关参数",
    height: [

    ],
  }
];
